<template>
	<div>
		<div class="queryBox">
			<div class="mb20">
				<span class="mr20"><b>{{$t('main.orderMenu.statisticsToday')}}:</b></span>
				<span class="inlineBlock mr20">{{$t('main.orderMenu.numberOfSupplementaryOrders')}}：<span class="cdanger">{{countAll.bd_count}}</span></span>
				<span class="inlineBlock mr20">{{$t('main.orderMenu.numberOfAdditionalOrders')}}：<span class="cdanger">{{countAll.bz_count}}</span></span>
				<span class="inlineBlock mr20">{{$t('main.orderMenu.numberOfCancellations')}}：<span class="cdanger">{{countAll.cd_count}}</span></span>
				<span class="inlineBlock mr20">{{$t('main.orderMenu.supplementAmount')}}：<span class="cdanger">{{countAll.bd_money | tofixed}}</span></span>
				<span class="inlineBlock mr20">{{$t('main.orderMenu.additionalOrderAmount')}}：<span class="cdanger">{{countAll.bz_money | tofixed}}</span></span>
				<span class="inlineBlock mr20">{{$t('main.orderMenu.cancellationAmount')}}：<span class="cdanger">{{countAll.cd_money | tofixed}}</span></span>
				<!-- <span class="inlineBlock mr20">成功金额：<span class="cdanger">{{countAll.success_money | tofixed}}</span></span> -->
				<!-- <span class="inlineBlock mr20">成功笔数：<span class="cdanger">{{countAll.success_count}}</span></span> -->
			</div>
			<!-- <div class="mb20">
				<el-input size="mini" :placeholder="$t('main.orderMenu.pleaseEnterTheOrderNumber')" v-model="order_id" clearable class="mr20" style="width: 200px"></el-input>
				<el-input size="mini" :placeholder="$t('main.orderMenu.pleaseEnterTransactionNo')" v-model="water_no" clearable class="mr20" style="width: 200px"></el-input>
				<el-input size="mini" :placeholder="$t('main.orderMenu.pleaseEnterTheMerchantNumber')" v-model="business_no" clearable class="mr20" style="width: 200px"></el-input>
				<el-input size="mini" :placeholder="$t('main.orderMenu.pleaseEnterTheNameOfTheBankCardHolder')" v-model="card_name" clearable class="mr20" style="width: 200px"></el-input>
				<el-input size="mini" :placeholder="$t('main.orderMenu.pleaseEnterTheMerchantName')" v-model="business_name" clearable class="mr20" style="width: 200px"></el-input>
				<el-input size="mini" :placeholder="$t('main.orderMenu.pleaseEnterTheSubmittedAmount')" v-model="add_gold" clearable class="mr20" style="width: 200px"></el-input>
				<el-input size="mini" :placeholder="$t('main.orderMenu.pleaseEnterIp')" v-model="req_ip" clearable class="mr20" style="width: 200px"></el-input>

			</div> -->
			<div class="mb20">
				<span>
					<span class="mr20">{{$t('main.orderMenu.orderType')}}：</span>
					<el-select v-model="orderType" :placeholder="$t('main.orderMenu.orderType')" size="mini" class="mr20">
						<el-option v-for="item in orderTypeArr" :key="item.key" :label="$t('main.orderMenu.'+item.name)" :value="item.key"></el-option>
					</el-select>
				</span>
				<el-button size="mini" plain class="mr20" @click="setDate(-1)" :disabled="disLoading">{{$t('main.orderMenu.theDayBefore')}}</el-button>
				<el-date-picker size="mini" v-model="date" type="daterange" :range-separator="$t('main.orderMenu.to')" start-placeholder="开始日期"
				 end-placeholder="结束日期" class="mr20"></el-date-picker>
				<el-button size="mini" plain class="mr20" @click="setDate(1)" :disabled="disLoading">{{$t('main.orderMenu.theNextDay')}}</el-button>
				<br/>
				<span>{{$t('main.orderMenu.collectionMethod')}}：</span>
				<el-select v-model="pay_type" :placeholder="$t('main.orderMenu.pleaseSelect')" size="mini" class="mr20" clearable>
					<el-option v-for="item in paywayArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				<span>{{$t('main.orderMenu.passageway')}}：</span>
				<el-select v-model="payway_type_id" :placeholder="$t('main.orderMenu.pleaseSelect')" size="mini" class="mr20" clearable>
					<el-option v-for="item in paywayTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				
				<span>{{$t('main.orderMenu.orderState')}}：</span>
				<el-select v-model="state" :placeholder="$t('main.orderMenu.pleaseSelect')" size="mini" class="mr20" clearable>
					<el-option v-for="(item,index) in stateArr" :key="index" :label="item" :value="index"></el-option>
				</el-select>
				
				<span>{{$t('main.orderMenu.merchant')}}：</span>
				<el-select v-model="business_no" :placeholder="$t('main.orderMenu.pleaseSelect')" size="mini" class="mr20" clearable>
					<el-option v-for="(item,index) in tradertableData" :key="item.business_no" :label="item.business_name" :value="item.business_no"></el-option>
				</el-select>
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'" @click="getData" :disabled="disLoading">{{$t('main.orderMenu.query')}}</el-button>
			</div>
		</div>
	<template>
			<div class="flexX flexEnd">
				  <el-select
				    class="mb10"
				    size="mini"
				    v-model="CheckedCol"
					@change="colChange"
				    multiple
				    collapse-tags
				    style="margin-left: 20px;"
				    placeholder="请选择">
				    <el-option
				      v-for="item in CheckColumn"
				      :key="item.value"
				      :label="item.label"
				      :value="item.value">
				    </el-option>
				  </el-select>
			</div>
			<el-table :max-height="height" size="mini" :data="tableData" stripe v-loading="loading" :show-overflow-tooltip="true">
				<el-table-column v-if="CheckedCol.includes('order_id')" fixed fit prop="order_id" :label="$t('main.orderMenu.orderNumber')">
					<template slot-scope="scope">
						<div v-if="scope.row.id" class="flexY flexStart">
							<div class="flexX flexStart">
								<div class="yctable" >商户:{{scope.row.order_id}}</div>
								<span> <i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.order_id)"></i></span>
							</div>
							<div class="flexX flexStart">
								<div class="yctable" >系统:{{scope.row.water_no}}</div>
								<span> <i style="color: #0039ff;" class="el-icon-document-copy" @click="handleCopy(scope.row.water_no)"></i></span>
							</div>
						</div>
						<div  v-if="!scope.row.id">
							<span v-if="scope.$index == tableData.length-2">单页合计</span>
							<span v-if="scope.$index == tableData.length-1">总计</span>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column v-if="CheckedCol.includes('state')" :label="$t('main.orderMenu.orderState')">
					<template slot-scope="scope">
						<div v-if="scope.row.id" :class="scope.row.state==1?'csuccess':scope.row.state==7?'cdanger':''">{{stateArr[scope.row.state]}}</div>
					</template>
				</el-table-column>
				<el-table-column  v-if="CheckedCol.includes('business_name')" label="商户信息"> 
					<template slot-scope="scope">
						<div v-if="scope.row.id">	
							{{scope.row.business_no}}<br/>
							{{scope.row.business_name}}
						</div>
					</template>
				</el-table-column>
				<el-table-column v-if="CheckedCol.includes('pay_type_name')" label="支付信息" width="130px" prop="pay_type_name">
					<template slot-scope="scope">
						<div v-if="scope.row.id">
							{{scope.row.pay_type_name}}<br>
							{{scope.row.payway_name}}
						</div>
					</template>
				</el-table-column>
				<el-table-column v-if="CheckedCol.includes('add_time')" label="时间" width="160px">
					<template slot-scope="scope">
						<div v-if="scope.row.id">
							提交:{{scope.row.add_time}}<br>
							完成:{{scope.row.action_time}}<br>
							耗时:<span class="cdanger" v-if="scope.row.action_time && scope.row.add_time">{{getTimeDifference(scope.row.add_time,scope.row.action_time)}}</span>
						</div>
				
					</template>
				</el-table-column>
				<el-table-column v-if="CheckedCol.includes('submit_gold')" :label="$t('main.orderMenu.orderAmount')">
					<template slot-scope="scope">
						<div v-if="scope.$index == tableData.length-2">
						<span>{{scope.row.submit_gold | tofixed}}</span>
						</div>
						<div v-else-if="scope.$index == tableData.length-1">
							<span>{{scope.row.submit_gold | tofixed}}</span>
						</div>
						<p v-else>{{scope.row.submit_gold | tofixed}}</p>
					</template>
				</el-table-column>
				<el-table-column v-if="CheckedCol.includes('gold')" :label="$t('main.orderMenu.actualAmount')">
					<template slot-scope="scope">
						<div v-if="scope.$index == tableData.length-2">
							<span>{{scope.row.gold | tofixed}}</span>
						</div>
						<div v-else-if="scope.$index == tableData.length-1">
							<span>{{scope.row.gold | tofixed}}</span>
						</div>
						<p v-else>{{scope.row.gold | tofixed}}</p>
					</template>
				</el-table-column>
				<el-table-column v-if="CheckedCol.includes('pay_name')" :label="$t('main.orderMenu.nameOfPayer')" prop="pay_name">
					<template slot-scope="scope">
						<div v-if="scope.row.id">
							{{scope.row.pay_name}}
						</div>
					</template>
				</el-table-column>
				<el-table-column v-if="CheckedCol.includes('fee_gold_tra')" label="费用">
					<template slot-scope="scope">
						<div v-if="scope.$index == tableData.length-2">
							<span>{{scope.row.fee_gold_tra | tofixed}}</span>
						</div>
						<div v-else-if="scope.$index == tableData.length-1">
							<span>{{scope.row.fee_gold_tra | tofixed}}</span>
						</div>
						<p v-else>{{scope.row.fee_gold_tra | tofixed}}</p>
					</template>
				</el-table-column>
				<el-table-column v-if="CheckedCol.includes('trader_gold')" label="商户入账">
					<template slot-scope="scope">
						<div v-if="scope.$index == tableData.length-2">
							<span>{{scope.row.trader_gold | tofixed}}</span>
						</div>
						<div v-else-if="scope.$index == tableData.length-1">
							<span>{{scope.row.trader_gold | tofixed}}</span>
						</div>
						<p v-else>{{scope.row.trader_gold | tofixed}}</p>
					</template>
				</el-table-column>
				<el-table-column :label="$t('main.orderMenu.reasonForReplenishment')" width="180px" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.id">
							<span class="cdanger">
								{{replenishmentStateObj[scope.row.replenish_state]}} 
							</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column fixed='right' :label="$t('main.orderMenu.function')">
					<template slot-scope="scope">
						<div v-if="scope.row.id">
							<div>{{scope.row.auditor}}</div>
							<div v-if="scope.row.state == 1">
								<div>
									<el-link type="primary" @click="noticeIn(scope.row,scope.$index)">{{$t('main.orderMenu.sendCallback')}}</el-link>
								</div>
							</div>
							<div v-if="scope.row.state == 7">
								<el-link v-if="scope.row.card && scope.row.is_manual == 0" type="primary" @click="showDia(scope.row,scope.$index)">补单</el-link>
							</div>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</template>
		<div class="flexX pageBox mt20">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="total"></el-pagination>
		</div>
		<el-dialog center class="dialog" top="100px" title="" :visible.sync="dialogVisible" @close="close" width="70%"
		 :close-on-click-modal="false">
			<div class="formBox" v-loading="dialogloading">
				<div>
					<el-tabs type="border-card" @tab-click="getInfoData" v-model="activeName">
						<el-tab-pane label="搬砖工" name='info'>
							<div class="flexX flexAround flexTop">

								<div>
									<el-form label-position="right" label-width="80px">
										<el-form-item label="用户名:">{{infoTotalData.name}}</el-form-item>
										<el-form-item label="账号:">{{infoTotalData.username}}</el-form-item>
										<el-form-item label="Skype:">
											<!-- {{infoTotalData.skype}} -->
											<el-link type="primary" :href="`skype:${infoTotalData.skype}?chat`" οnclick="return skypeCheck();">{{infoTotalData.skype}}</el-link>
										</el-form-item>
										<el-form-item label="手机号:">{{infoTotalData.telephone}}</el-form-item>
										<el-form-item label="上级:">{{infoTotalData.all_parent_name}}</el-form-item>
									</el-form>
								</div>
								<div>
									<el-form label-position="right" label-width="80px">
										<el-form-item label="奖金:">{{infoTotalData.total_yj | tofixed}}</el-form-item>
										<el-form-item label="提成:">{{infoTotalData.total_tc | tofixed}}</el-form-item>
										<el-form-item label="今日奖金:">{{infoTotalData.today_yj | tofixed}}</el-form-item>
										<el-form-item label="今日提成:">{{infoTotalData.today_tc | tofixed}}</el-form-item>
										<el-form-item label="卡数量:">{{infoTotalData.card_count}}</el-form-item>
									</el-form>
								</div>
							</div>
						</el-tab-pane>
						<!-- <el-tab-pane label="卡列表" name='bankList'>
							<div class="mb20"><span class="mr20">用户名：{{infoTotalData.name}}</span><span>账号：{{infoTotalData.username}}</span></div>
							<el-table size="mini" :data="cardListData" border="" stripe max-height="500px">
								<el-table-column prop="card" label="卡号" width="145px" align="center"></el-table-column>
								<el-table-column prop="bank" label="银行" align="center"></el-table-column>
								<el-table-column prop="true_name" label="姓名" align="center"></el-table-column>
								<el-table-column prop="telephone" label="电话" align="center"></el-table-column>

								<el-table-column label="昨日结余" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.yes_true_quota | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column label="今日收款" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.money_in | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column label="今日出款" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.money_out | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column label="当前余额" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.true_quota | tofixed}}</div>
									</template>
								</el-table-column>
							</el-table>
							<div class="flexX  mt20">
								<el-pagination :hide-on-single-page="true" @size-change="diahandleSizeChange" @current-change="diahandleCurrentChange"
								 :current-page="diapage" :page-sizes="diapageSizesArr" :page-size="diapageSize" layout="total, sizes, prev, pager, next, jumper"
								 :total="diatotal"></el-pagination>
							</div>
						</el-tab-pane> -->
						<el-tab-pane label="卡进账订单" name='cardNote'>
							<div class="mb20"><span class="mr20">用户名：{{infoTotalData.name}}</span><span>账号：{{infoTotalData.username}}</span></div>
							<el-table size="mini" :data="cardNoteData" border="" stripe max-height="500px">
								<el-table-column prop="true_name" label="姓名" align="center"></el-table-column>
								<el-table-column prop="card" label="卡号" align="center"></el-table-column>
								<el-table-column prop="order_id" label="订单号" align="center"></el-table-column>

								<el-table-column :label="$t('main.orderMenu.orderAmount')" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.gold | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="action_time" label="时间" align="center"></el-table-column>

							</el-table>
							<div class="flexX  mt20">
								<el-pagination :hide-on-single-page="true" @size-change="nodehandleSizeChange" @current-change="nodehandleCurrentChange"
								 :current-page="nodepage" :page-sizes="nodepageSizesArr" :page-size="nodepageSize" layout="total, sizes, prev, pager, next, jumper"
								 :total="nodetotal"></el-pagination>
							</div>
						</el-tab-pane>

						<el-tab-pane label="卡出账订单" name='cardNoteOut'>
							<el-table size="mini" :data="cardNoteOutData" border="" stripe max-height="500px">
								<el-table-column prop="true_name" label="姓名" align="center"></el-table-column>
								<el-table-column prop="card" label="卡号" align="center"></el-table-column>
								<el-table-column prop="order_id" label="订单号" align="center"></el-table-column>
								<el-table-column :label="$t('main.orderMenu.orderAmount')" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.gold | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="action_time" label="时间" align="center"></el-table-column>
							</el-table>
							<div class="flexX  mt20">
								<el-pagination :hide-on-single-page="true" @size-change="outhandleSizeChange" @current-change="outhandleCurrentChange"
								 :current-page="outpage" :page-sizes="outpageSizesArr" :page-size="outpageSize" layout="total, sizes, prev, pager, next, jumper"
								 :total="outtotal"></el-pagination>
							</div>
						</el-tab-pane>

						<!-- 卡账单 -->
						<el-tab-pane label="卡账单" name='cardBill'>
							<div class="mb20">
								<span class="mr20">用户名：{{infoTotalData.name}}</span>
								<span class="mr20">账号：{{infoTotalData.username}}</span>
								<span class="mr20" v-if="cardBillData.length>0">姓名：{{cardBillData[0].name}}</span>
								<span class="mr20" v-if="cardBillData.length>0">卡号：{{cardBillData[0].card}}</span>
								<span>更新时间：{{bill_update_time}}</span>
							</div>
							<el-table size="mini" :data="cardBillData" border="" stripe max-height="500px">
								<el-table-column type="index" label="序号" align="center" width="50px"></el-table-column>
								<el-table-column prop="trans_date" label="交易时间" align="center" min-width="140px"></el-table-column>
								<el-table-column :label="$t('main.orderMenu.orderAmount')" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.amount | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="transplace" label="收支摘要" align="center" min-width="140px">
									<template slot-scope='scope'>
										<div :class="scope.row.type=='出账'?'cdanger':'csuccess'">{{scope.row.transplace}}</div>
									</template>
								</el-table-column>
								<el-table-column label="账户余额" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.keep_amount | tofixed}}</div>
									</template>
								</el-table-column>								
								<el-table-column prop="pay_card" label="对方账户" align="center" min-width="140px"></el-table-column>
								<el-table-column prop="pay_name" label="对方户名" align="center" width="140px"></el-table-column>								
								<el-table-column prop="remark" :label="$t('main.orderMenu.remarks')" align="center" width="140px"></el-table-column>								
								
							</el-table>
							<div class="flexX  mt20">
								<el-pagination :hide-on-single-page="true" @size-change="billhandleSizeChange" @current-change="billhandleCurrentChange"
								 :current-page="billpage" :page-sizes="billpageSizesArr" :page-size="billpageSize" layout="total, sizes, prev, pager, next, jumper"
								 :total="billtotal"></el-pagination>
							</div>
						</el-tab-pane>


					</el-tabs>
				</div>
				<div class="textCenter">
					<el-button type="primary" @click="close">关闭</el-button>
				</div>
			</div>
		</el-dialog>
		<el-dialog width="800px" center top="150px" title="补单" :visible.sync="bddialogVisible" @close="bdclose"
		 :close-on-click-modal="false">
			<div class="formBoxbd" v-loading="bddialogloading">
				<div>
					<el-form label-position="right" label-width="100px">
						<el-form-item :label="$t('main.orderMenu.orderNumber')">
							{{item.order_id}}
						</el-form-item>
						<el-form-item :label="$t('main.orderMenu.transactionNo')">
							{{item.water_no}}
						</el-form-item>
						<el-form-item :label="$t('main.orderMenu.merchantName')">
							{{item.business_name}}
						</el-form-item>
						<el-form-item :label="$t('main.orderMenu.orderAmount')">
							{{item.add_gold | tofixed}}元
						</el-form-item>
						<el-form-item label="补单类型">
							<el-select v-model="replenState" :placeholder="$t('main.orderMenu.pleaseSelect')" size="mini" class="mr20" clearable>
								<el-option v-for="(item,index) in replenishmentStateObj" :key="index" :label="item" :value="index"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="补单金额">
							<el-input v-model="amount" placeholder="请输入补单金额"></el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="textCenter">
					<el-button type="primary" @click="onSubmit">确定</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import local from '@/util/local'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isMobile,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				disLoading: false,
				tableData: [],
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				diapageSizesArr: [20, 50, 100, 200],
				diapageSize: 20,
				diatotal: 0,
				diapage: 1,
				nodepageSizesArr: [20, 50, 100, 200],
				nodepageSize: 20,
				nodetotal: 0,
				nodepage: 1,
				outpageSizesArr: [20, 50, 100, 200],
				outpageSize: 20,
				outtotal: 0,
				outpage: 1,
				billpageSizesArr: [20, 50, 100, 200],
				billpageSize: 20,
				billtotal: 0,
				billpage: 1,
				order_id: '',
				water_no: '',
				business_no: '',
				card_name: '',
				business_name: '',
				add_gold: '',
				req_ip: '',
				pay_type: '',
				state: '',
				paywayArr: [],
				stateArr: {},
				// stateMap:{},
				date: null,
				countAll: {
					total_money: 0,
					success_money: 0,
					total_count: 0,
					success_count: 0,
				},
				dialogVisible: false,
				dialogloading: false,
				item: {},
				infoTotalData: {}, //搬砖工
				cardListData: [], //银行卡列表
				cardNoteData: [], //银行卡记录  流水
				cardNoteOutData: [], //卡出账记录 
				cardBillData: [], //卡账单
				bill_update_time: '', //卡账单 最后更新时间
				activeName: 'info',
				//补单
				replenState: '',
				replenishmentStateObj: {}, //补单类型
				bddialogVisible: false, //补单弹框
				amount: '', //补单金额
				item: {}, //当前补单的数据
				index: {}, //当前item 索引
				bddialogloading: false,
				//orderType type   //订单类型 1-补单+补增 2-补单 3-补增 4-撤单
				orderType:1,
				orderTypeArr:[{
					key:1,
					name:"allSupplements",
				},{
					key:2,
					name:"supplementaryOrder",
				},{
					key:3,
					name:"additionalOrder",
				},{
					key:4,
					name:"cancelTheOrder",
				}],
				height:0,
				traderpage:1,
				traderpageSize:1000,
				tradertableData:[],
				paywayTypeList:[],//通道的筛选项
				payway_type_id:'',		
				// 选择列是否展示
				CheckColumn:[
					{value: 'order_id',label: '订单编号'},
					{value: 'state',label: '订单状态'},
					{value: 'business_name',label: '商户信息'},
					{value: 'pay_type_name',label: '支付信息'},
					{value: 'add_time',label: '时间'},
					{value: 'submit_gold',label: '订单金额'},
					{value: 'gold',label: '实际金额'},
					{value: 'pay_name',label: '付款人'},
					{value: 'fee_gold_tra',label: '费用'},
					{value: 'trader_gold',label: '商户入账'},
				],
				CheckedCol:[],		
			};
		},
		created() {
			this.date = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(new Date().getTime(),true)];
			this.paywayTypeAll()
			this.paywayAll()
			this.stateList()
			if(local.get('orderCol')){
				this.CheckedCol = local.get('orderCol')
			}else{
				this.CheckedCol = this.CheckColumn.map(column => column.value);
			}
			this.orderAllList()
			this.orderCountAll()
			this.replenishmentState()
			this.traderListtList()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				// let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
			})
		},
		mounted(){
			let self = this
			document.onkeydown = function(e) {
			  let ev = document.all ? window.event : e
			  if (ev.keyCode === 13) {
				  if(!self.loading){
					  self.getData()
				  }
				
			  }
			}
		},
		destroyed() {
			document.onkeydown = null
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// console.log('sssssssssss', (value * 1).toFixed(2) + '')
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			colChange(val){
				local.set('orderCol', val)
			},
			// 复制
			handleCopy(textCopy){
				
				const contentToCopy = textCopy
				// 创建一个临时的 textAREA 元素
				const textarea = document.createElement('textarea')
				textarea.value = contentToCopy
				document.body.appendChild(textarea)
				textarea.select()
				document.execCommand('copy')
				document.body.removeChild(textarea)
				this.$message({
					type: 'success',
					message: '已复制到剪切板'
				})
			},
			setDate(day) {
				let data = this.date ? this.date[0] : this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate, newDate]
				this.getData()
			},
			getTimeDifference(startTime, endTime) {
				return this.$util.intervalTime(startTime, endTime)
			},
			//搜索按钮
			getData() {
				this.page = 1;
				this.orderAllList();
				this.orderCountAll();
			},
			//总计
			orderCountAll() {
				this.$api.replenishmentCountAll({}).then(res => {
					console.log(res)
					if (res.status == 1) {
						this.countAll = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			//导出Excel
			getExcel() {
				//manager/order/exportList
				console.log(this.$baseURL)
				// let data = {
				let username = local.get('username');
				let tid = local.get('tid')
				let start_time = this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0])), true) : '';
				let end_time = this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1])), true) : '';
				// }
				let href =
					`${this.$baseURL}/manager/order/exportList?username=${username}&tid=${tid}&order_id=${this.order_id}&water_no=${this.water_no}&business_no=${this.business_no}&card_name=${this.card_name}&business_name=${this.business_name}&add_gold=${this.add_gold}&req_ip=${this.req_ip}&pay_type=${this.pay_type}&state=${this.state}&start_time=${start_time}&end_time=${end_time}`
				window.location.href = href
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val
				this.page = 1
				this.orderAllList()
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val
				this.orderAllList()
			},
			diahandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.diapage = 1
				this.diapageSize = val
				this.cardList()
			},
			diahandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.diapage = val
				this.cardList()
			},

			nodehandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.nodepageSize = val
				this.nodepage = 1
				this.moneyList()
			},
			nodehandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.nodepage = val
				this.moneyList()
			},
			outhandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.outpage = 1
				this.outpageSize = val
				this.outList()
			},
			outhandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.outpage = val
				this.outList()
			},
			billhandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.billpage = 1
				this.billpageSize = val
				this.cardBill()
			},
			billhandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.billpage = val
				this.cardBill()
			},

			paywayAll() {
				this.$api.paywayAll({hasRepay:2}).then(res => {
					if (res.status === 1) {
						this.paywayArr = res.data.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			stateList() {
				this.$api.stateList({}).then(res => {
					if (res.status === 1) {
						this.stateArr = res.data
						// this.stateArr.forEach(item=>{
						// 	this.stateMap[item.id] = item.note
						// })
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			orderAllList() {
				let data = {
					type:this.orderType,
					order_id: this.order_id,
					water_no: this.water_no,
					business_no: this.business_no,
					card_name: this.card_name,
					business_name: this.business_name,
					add_gold: this.add_gold,
					req_ip: this.req_ip,
					pay_type: this.pay_type,
					state: this.state,
					// start_time: this.date?this.date[0]:'',
					// end_time:  this.date?this.date[1]:'',
					start_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0])), true) : '',
					end_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1])), true) : '',
					page: this.page,
					size: this.pageSize,
					payway_type_id:this.payway_type_id
				}
				// this.loading = true
				this.disLoading = true
				console.log('disLoading', this.disLoading)
				this.$api.orderAllList(data).then(res => {
					this.loading = false;
					this.disLoading = false;
					if (res.status === 1) {
						this.tableData = res.data.data;
						this.total = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
			},
			showInfo(item) {
				// this.$router.push({path:'/orderInfo'})
				this.dialogVisible = true;
				this.item = item
				this.infoTotal(item)
			},
			getInfoData(val) {
				if (val.name == 'info') {
					if (JSON.stringify(this.infoTotalData) == '{}') {
						this.infoTotal()
					}
				}
				if (val.name == 'bankList') {
					if (this.cardListData.length == 0) {
						this.cardList()
					}
				}
				if (val.name == 'cardNote') {
					if (this.cardNoteData.length == 0) {
						this.moneyList()
					}
				}
				if (val.name == 'cardNoteOut') {
					if (this.cardNoteOutData.length == 0) {
						this.outList()
					}
				}
				if (val.name == 'cardBill') {
					if (this.cardBillData.length == 0) {
						this.cardBill()
					}
				}
				console.log('val', val)
			},
			infoTotal(item) {
				let data = {
					id: item.bank_card_id,
					type: 2
				}
				// this.dialogloading = true
				this.$api.infoTotal(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.infoTotalData = res.data
					} else {
						this.$message({
							"type": "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			cardList(item) {
				let data = {
					carder_id: this.infoTotalData.id,
					size: this.diapageSize,
					page: this.diapage,
					// type:2
				}

				// this.dialogloading = true
				this.$api.cardList(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.cardListData = res.data.data
						this.diatotal = res.data.total

					} else {
						this.$message({
							"type": "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			close() {
				this.dialogVisible = false
				this.item = {}
				this.infoTotalData = {} //搬砖工
				this.cardListData = [] //银行卡列表
				this.cardNoteData = [] //银行卡记录  流水
				this.cardNoteOutData = []
				this.cardBillData = []
				this.activeName = 'info'
				this.diapage = 1
				this.nodepage = 1
				this.outpage = 1
				this.billpage = 1

			},

			//补单按钮
			replenishmentState() {
				this.$api.replenishmentState({}).then(res => {
					if (res.status == 1) {
						this.replenishmentStateObj = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			//通知商家
			noticeIn(item, index) {
				this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
					confirmButtonText: this.$t('main.common.submit'),
					cancelButtonText: this.$t('main.common.cancel'),
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id
					}
					this.loading = true
					this.$api.noticeIn(data).then(res => {
						this.loading = false;
						if (res.status == 1) {
							this.$message ({
								type: 'success',
								message: this.$t('main.common.successConfirm')
							})
						} else {
							this.$message ({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消发送回调'
					});
				});
			},

			showDia(item, index) {
				this.item = item
				this.index = index
				// this.orderType=type
				this.bddialogVisible = true
				if (JSON.stringify(this.replenishmentStateObj) == '{}') {
					this.replenishmentState()
				}

			},
			//确认补单
			onSubmit() {
				if (isEmpty(this.replenState, '请选择补单类型')) {
					return
				}
				if (isEmpty(this.amount, '请选输入补单金额')) {
					return
				}
				if (!isMoney(this.amount, '补单金额只能是数字，且最多保留两位小数')) {
					return
				}
				let data = {
					id: this.item.id,
					state: this.replenState,
					amount: this.amount
				}
				this.bddialogloading = true;
				this.$api.replenishment(data).then(res => {
					this.bddialogloading = false;
					if (res.status == 1) {
						this.orderAllList()
						this.bdclose();
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(() => {
					this.bddialogloading = false;
				})
			},
			bdclose() {
				this.item = {}
				this.bddialogVisible = false
				this.replenState = ''
				this.amount = ''
			},
			moneyList() {
				let data = {
					page: this.nodepage,
					size: this.nodepageSize,
					id: this.item.bank_card_id,
				}
				// this.dialogloading = true
				this.$api.moneyList(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.cardNoteData = res.data.data
						this.nodetotal = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			outList() {
				let data = {
					page: this.outpage,
					size: this.outpageSize,
					id: this.item.bank_card_id,
				}
				// this.dialogloading = true
				this.$api.outList(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.cardNoteOutData = res.data.data
						this.outtotal = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			//卡账单
			cardBill() {
				let data = {
					page: this.billpage,
					size: this.billpageSize,
					id: this.item.bank_card_id,
				}
				// this.dialogloading = true
				this.$api.cardBill(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.cardBillData = res.data.data
						this.billtotal = res.data.total
						this.bill_update_time = res.data.bill_update_time
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			//撤单
			ordercancel(item, index) {
				this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
					confirmButtonText: this.$t('main.common.submit'),
					cancelButtonText: this.$t('main.common.cancel'),
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id
					}
					this.loading = true
					this.$api.ordercancel(data).then(res => {
						this.loading = false
						if (res.status == 1) {
							this.orderAllList()
							this.$message({
								type: 'success',
								message: this.$t('main.common.successConfirm')
							});
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
					}).catch(error => {
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消撤单'
					});
				});
			},
			traderListtList() {
				this.$api.traderList({
					page: this.traderpage,
					size: this.traderpageSize
				}).then(res => {
					if (res.status === 1) {
						this.tradertableData = res.data.data
						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
				})
			},
			//通道的筛选项
			paywayTypeAll(){
				this.$api.paywayTypeAll({}).then(res=>{
					this.paywayTypeList = res.data
				})
			},

		},
		watch: {
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					// let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
				})
			}
		},
	};
</script>

<style scoped="scoped">
	.formBoxbd {
		width: 80%;
		margin: 0 auto;
	}
</style>
